import React, {useEffect, useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {setToken, setUserByToken} from "../../redux/Slicers/authSlice";
import {setSelected} from "../../redux/Slicers/branchSlice";
import useBranch from "../../core/api/useBranch";
import useApi from "../../core/hooks/useApi";
import {setAppConfigs} from "../../redux/Slicers/appSlice";
import {Spin} from "antd";

const AuthRouteMiddleware = ({
    children
}) => {

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const {getBranchesIfEmpty} = useBranch();

    const api = useApi();

    useEffect(() => {
        setLoading(true);
        const sessionToken = localStorage.getItem("token");
        if (!!sessionToken) {
            batch(() => {
                dispatch(setToken(sessionToken));
                dispatch(setUserByToken(sessionToken));
            });
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        setLoading(true);
        const selectedB = localStorage.getItem("branch_id");
        if (!!selectedB)
            dispatch(setSelected(parseInt(selectedB)));
        setLoading(false);
    }, []);

    useEffect(() => {
        getAppConfigs();
        getBranchesIfEmpty();
    }, []);

    const getAppConfigs = () => {
        setLoading(true);
        api.get("app-configs/index", null, (res) => {
            if (res.success)
                dispatch(setAppConfigs(res?.data));
        }, () => setLoading(false))
    }

    return (
        <>
            {loading ? (
                    <>
                        <Spin spinning={true} />
                    </>
                )
                : (<>{children}</>)}
        </>
    );
}

export default AuthRouteMiddleware;