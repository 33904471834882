import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orders: [],
    userOrders: [],
}

export const orderSlice = createSlice({
    name:"order",
    initialState,
    reducers: {

        setOrders: (state, action) => {
            state.orders = action.payload;
        },

        appendOrders: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.orders,
                ];
            else
                users = [
                    action.payload,
                    ...state.orders,
                ];
            state.orders = users;
        },

        updateOrdersById: (state, action) => {
            let users = state.orders?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.orders = users;
        },

        deleteOrdersById: (state, action) => {
            state.orders = state.orders?.filter(item => item.id !== action.payload);
        },

        resetOrders: (state) => {
            state.orders = [];
        },

        setUserOrders: (state, action) => {
            state.userOrders = action.payload;
        },

        appendUserOrders: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.userOrders,
                ];
            else
                users = [
                    action.payload,
                    ...state.userOrders,
                ];
            state.userOrders = users;
        },

        updateUserOrdersById: (state, action) => {
            let users = state.userOrders?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.userOrders = users;
        },

        deleteUserOrdersById: (state, action) => {
            state.userOrders = state.userOrders?.filter(item => item.id !== action.payload);
        },

        resetUserOrders: (state) => {
            state.userOrders = [];
        },
    }
});

export const  {setOrders, appendOrders, deleteOrdersById, resetOrders, updateOrdersById, setUserOrders, appendUserOrders, updateUserOrdersById, deleteUserOrdersById, resetUserOrders} = orderSlice.actions;

export default orderSlice.reducer;