import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    branches: [],
    selected: null,
}

export const branchSlice = createSlice({
    name:"branch",
    initialState,
    reducers: {

        setBranches: (state, action) => {
            state.branches = action.payload;
        },

        appendBranches: (state, action) => {
            let branches = [];
            if(Array.isArray(action.payload))
                branches = [
                    ...action.payload,
                    ...state.branches,
                ];
            else
                branches = [
                    action.payload,
                    ...state.branches,
                ];
            state.branches = branches;
        },

        updateBranchesById: (state, action) => {
            let branches = state.branches?.filter(item => item.id !== action.payload.id);
            branches = [
                action.payload.data,
                ...branches
            ];
            state.branches = branches;
        },

        deleteBranchesById: (state, action) => {
            state.branches = state.branches?.filter(item => item.id !== action.payload);
        },

        resetBranches: (state) => {
            state.branches = [];
        },
        setSelected: (state, action) => {
            state.selected = action.payload;
        }
    }
});

export const  {setSelected, setBranches, appendBranches, updateBranchesById, deleteBranchesById, resetBranches} = branchSlice.actions;

export default branchSlice.reducer;