import React from "react";
import {Spin} from "antd";

const AppLoading = ({
    loading,
    children
}) => (
    <>
        <Spin tip="Yükleniyor..." spinning={loading} size="large">
            {children}
        </Spin>
    </>
);

export default AppLoading;