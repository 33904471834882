import {configureStore} from '@reduxjs/toolkit'
import authSlice from "./Slicers/authSlice";
import appSlice from "./Slicers/appSlice";
import userSlice from "./Slicers/userSlice";
import branchSlice from "./Slicers/branchSlice";
import passiveReasonSlice from "./Slicers/passiveReasonSlice";
import orderSlice from "./Slicers/orderSlice";
import callSlice from "./Slicers/callSlice";
import meetingSlice from "./Slicers/meetingSlice";
import userTimeSlice from "./Slicers/userTimeSlice";
import paymentTypeSlice from "./Slicers/paymentTypeSlice";
import orderTypeSlice from "./Slicers/orderTypeSlice";
import userTypeSlice from "./Slicers/userTypeSlice";
import callResultSlice from "./Slicers/callResultSlice";
import meetingTypeSlice from "./Slicers/meetingTypeSlice";
import noteSlice from "./Slicers/noteSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        app: appSlice,
        user: userSlice,
        branch: branchSlice,
        order: orderSlice,
        passiveReason: passiveReasonSlice,
        calls: callSlice,
        meetings: meetingSlice,
        times: userTimeSlice,
        paymentType: paymentTypeSlice,
        orderType: orderTypeSlice,
        userType: userTypeSlice,
        callResult: callResultSlice,
        meetingType: meetingTypeSlice,
        note: noteSlice,
    },
});