import React from "react";

const RouteList = [
    {
        middleware: "auth",
        path: "/",
        component: React.lazy(() => import("./Meetings")),
    },
    {
        middleware: "guest",
        path: "/login",
        component: React.lazy(() => import("./Login")),
    },
    {
        middleware: "auth",
        path: "/update-my-account",
        component: React.lazy(() => import("./UpdateAccount")),
    },
    {
        middleware: "auth",
        path: "/users",
        component: React.lazy(() => import("./Users")),
    },
    {
        middleware: "auth",
        path: "/users/discontinuous",
        component: React.lazy(() => import("./Users/DiscontinuousUser")),
    },
    {
        middleware: "auth",
        path: "/users/continuousUser",
        component: React.lazy(() => import("./Users/ContinuousUser")),
    },
    {
        middleware: "auth",
        path: "/users/packageIndestructible",
        component: React.lazy(() => import("./Users/PackageIndestructible")),
    },
    {
        middleware: "auth",
        path: "/branches",
        component: React.lazy(() => import("./Branches")),
    },
    {
        middleware: "auth",
        path: "/meetings",
        component: React.lazy(() => import("./Meetings")),
    },
    {
        middleware: "auth",
        path: "/canceled-meetings",
        component: React.lazy(() => import("./Meetings/CanceledMeetings")),
    },
    {
        middleware: "auth",
        path: "/line-close",
        component: React.lazy(() => import("./LineClose")),
    },
    {
        middleware: "auth",
        path: "/user-times",
        component: React.lazy(() => import("./UserTime")),
    },
    {
        middleware: "auth",
        path: "/passive-reasons",
        component: React.lazy(() => import("./PassiveReason")),
    },
    {
        middleware: "auth",
        path: "/payment-types",
        component: React.lazy(() => import("./PaymentType")),
    },
    {
        middleware: "auth",
        path: "/order-types",
        component: React.lazy(() => import("./OrderType")),
    },
    {
        middleware: "auth",
        path: "/user-types",
        component: React.lazy(() => import("./UserType")),
    },
    {
        middleware: "auth",
        path: "/meeting-types",
        component: React.lazy(() => import("./MeetingType")),
    },
    {
        middleware: "auth",
        path: "/call-results",
        component: React.lazy(() => import("./CallResult")),
    },
    {
        middleware: "auth",
        path: "/orders",
        component: React.lazy(() => import("./Order")),
    },
    {
        middleware: "auth",
        path: "/statics/selling/order-type",
        component: React.lazy(() => import("./Statics/OrderType")),
    },
    {
        middleware: "auth",
        path: "/statics/selling/payment-type",
        component: React.lazy(() => import("./Statics/PaymentType")),
    },
    {
        middleware: "auth",
        path: "/statics/selling/without-kdv",
        component: React.lazy(() => import("./Statics/WithOutKdv")),
    },
    {
        middleware: "auth",
        path: "/statics/selling/without-commission",
        component: React.lazy(() => import("./Statics/WithOutCommission")),
    },
    {
        middleware: "auth",
        path: "/statics/selling/net-fee",
        component: React.lazy(() => import("./Statics/NetFee")),
    },
    {
        middleware: "auth",
        path: "/statics/selling/general-reports",
        component: React.lazy(() => import("./Statics/GeneralReport")),
    },
    {
        middleware: "auth",
        path: "/statics/performance/seans",
        component: React.lazy(() => import("./Statics/SeansPerformance")),
    },
    {
        middleware: "auth",
        path: "/statics/performance/customer-transformation",
        component: React.lazy(() => import("./Statics/CustomerTransformation")),
    },
    {
        middleware: "auth",
        path: "/statics/performance/where-did-they-hear-it",
        component: React.lazy(() => import("./Statics/WhereDidTheyHearIt")),
    },
    {
        middleware: "auth",
        path: "/statics/performance/trial-results",
        component: React.lazy(() => import("./Statics/TrialResult")),
    },
    {
        middleware: "auth",
        path: "/statics/performance/trial-success-results",
        component: React.lazy(() => import("./Statics/TrialSuccessResult")),
    },
    {
        middleware: "auth",
        path: "/statics/performance/package-renewal",
        component: React.lazy(() => import("./Statics/PackageRenewal")),
    },
    {
        middleware: "auth",
        path: "/app-configs",
        component: React.lazy(() => import("./AppConfig")),
    },
];

export default RouteList;