import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orderTypes: [],
}

export const orderTypeSlice = createSlice({
    name:"orderType",
    initialState,
    reducers: {

        setOrderTypes: (state, action) => {
            state.orderTypes = action.payload;
        },

        appendOrderTypes: (state, action) => {
            let orderTypes = [];
            if(Array.isArray(action.payload))
                orderTypes = [
                    ...action.payload,
                    ...state.orderTypes,
                ];
            else
                orderTypes = [
                    action.payload,
                    ...state.orderTypes,
                ];
            state.orderTypes = orderTypes;
        },

        updateOrderTypesById: (state, action) => {
            let orderTypes = state.orderTypes?.filter(item => item.id !== action.payload.id);
            orderTypes = [
                action.payload.data,
                ...orderTypes
            ];
            state.orderTypes = orderTypes;
        },

        deleteOrderTypesById: (state, action) => {
            state.orderTypes = state.orderTypes?.filter(item => item.id !== action.payload);
        },

        resetOrderTypes: (state) => {
            state.orderTypes = [];
        },
    }
});

export const  {setOrderTypes, appendOrderTypes, updateOrderTypesById, resetOrderTypes, deleteOrderTypesById, } = orderTypeSlice.actions;

export default orderTypeSlice.reducer;