import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    paymentTypes: [],
}

export const paymentTypeSlice = createSlice({
    name:"paymentType",
    initialState,
    reducers: {

        setPaymentTypes: (state, action) => {
            state.paymentTypes = action.payload;
        },

        appendPaymentTypes: (state, action) => {
            let paymentTypes = [];
            if(Array.isArray(action.payload))
                paymentTypes = [
                    ...action.payload,
                    ...state.paymentTypes,
                ];
            else
                paymentTypes = [
                    action.payload,
                    ...state.paymentTypes,
                ];
            state.paymentTypes = paymentTypes;
        },

        updatePaymentTypesById: (state, action) => {
            let paymentTypes = state.paymentTypes?.filter(item => item.id !== action.payload.id);
            paymentTypes = [
                action.payload.data,
                ...paymentTypes
            ];
            state.paymentTypes = paymentTypes;
        },

        deletePaymentTypesById: (state, action) => {
            state.paymentTypes = state.paymentTypes?.filter(item => item.id !== action.payload);
        },

        resetPaymentTypes: (state) => {
            state.paymentTypes = [];
        },
    }
});

export const  {setPaymentTypes, appendPaymentTypes, updatePaymentTypesById, resetPaymentTypes, deletePaymentTypesById, } = paymentTypeSlice.actions;

export default paymentTypeSlice.reducer;