import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    sideCollapsed: false,
    drawerOpened: false,
    appConfigs: null,
}

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        toggleLoading: (state) => {
            state.loading = !state.loading;
        },
        toggleSide: (state) => {
            state.sideCollapsed = !state.sideCollapsed;
        },
        toggleDrawer: (state) => {
            state.drawerOpened = !state.drawerOpened;
        },
        setDrawer: (state, action) => {
            state.drawerOpened = action.payload;
        },
        setSlider: (state, action) => {
            state.sideCollapsed = action.payload;
        },
        setAppConfigs: (state, action) => {
            state.appConfigs = action.payload;
        },
    }
});

export const {toggleLoading, setAppConfigs, setDrawer, toggleDrawer, toggleSide} = appSlice.actions;

export default appSlice.reducer;