import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    calls: [],
    userCalls: [],
}

export const callSlice = createSlice({
    name:"order",
    initialState,
    reducers: {

        setCalls: (state, action) => {
            state.calls = action.payload;
        },

        appendCalls: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.calls,
                ];
            else
                users = [
                    action.payload,
                    ...state.calls,
                ];
            state.calls = users;
        },

        updateCallsById: (state, action) => {
            let users = state.calls?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.calls = users;
        },

        deleteCallsById: (state, action) => {
            state.calls = state.calls?.filter(item => item.id !== action.payload);
        },

        resetCalls: (state) => {
            state.calls = [];
        },

        setUserCalls: (state, action) => {
            state.userCalls = action.payload;
        },

        appendUserCalls: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.userCalls,
                ];
            else
                users = [
                    action.payload,
                    ...state.userCalls,
                ];
            state.userCalls = users;
        },

        updateUserCallsById: (state, action) => {
            let users = state.userCalls?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.userCalls = users;
        },

        deleteUserCallsById: (state, action) => {
            state.userCalls = state.userCalls?.filter(item => item.id !== action.payload);
        },

        resetUserCalls: (state) => {
            state.userCalls = [];
        },
    }
});

export const  {
    setCalls,
    setUserCalls,
    appendCalls,
    appendUserCalls,
    deleteCallsById,
    deleteUserCallsById,
    resetCalls,
    resetUserCalls,
    updateCallsById,
    updateUserCallsById
} = callSlice.actions;

export default callSlice.reducer;