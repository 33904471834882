import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    reasons: [],
}

export const passiveReasonSlice = createSlice({
    name:"passiveReason",
    initialState,
    reducers: {

        setReasons: (state, action) => {
            state.reasons = action.payload;
        },

        appendReasons: (state, action) => {
            let reasons = [];
            if(Array.isArray(action.payload))
                reasons = [
                    ...action.payload,
                    ...state.reasons,
                ];
            else
                reasons = [
                    action.payload,
                    ...state.reasons,
                ];
            state.reasons = reasons;
        },

        updateReasonsById: (state, action) => {
            let reasons = state.reasons?.filter(item => item.id !== action.payload.id);
            reasons = [
                action.payload.data,
                ...reasons
            ];
            state.reasons = reasons;
        },

        deleteReasonsById: (state, action) => {
            state.reasons = state.reasons?.filter(item => item.id !== action.payload);
        },

        resetReasons: (state) => {
            state.reasons = [];
        },
    }
});

export const  {setReasons, appendReasons, updateReasonsById, resetReasons, deleteReasonsById, } = passiveReasonSlice.actions;

export default passiveReasonSlice.reducer;