import React from "react";
import {Layout} from 'antd';
import AppRouter from "../AppRouter";
import {useDispatch, useSelector} from "react-redux";
import AppLoading from "../AppLoading";
import Sidebar from "./Sidebar";
import {Hidden, IconButton} from "@mui/material";
import AppDrawer from "./AppDrawer";
import {UnorderedListOutlined} from "@ant-design/icons";
import {toggleDrawer} from "../../redux/Slicers/appSlice";

const {Header, Content} = Layout;

const AppLayout = () => {

    const dispatch = useDispatch();

    const {loading} = useSelector((state) => state.app);
    const token = useSelector((state) => state.auth.token);

    const drawerOnClick = () => {
        dispatch(toggleDrawer());
    }

    return (
        <>
            <AppLoading loading={loading}>
                {!!token ? (
                    <>
                        <Layout>
                            <Header
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: "#317eeb",
                                    backgroundImage: "linear-gradient(#b1d6f1, #0d3467)"
                                }}
                            >
                                <div className="d-flex gap-2">
                                    <img src="/superslow-logo.png" alt=""/>
                                    <Hidden smDown>
                                        <h1 className="text-white text">SUPERSLOW</h1>
                                    </Hidden>
                                    <Hidden smUp>
                                        <IconButton className="text-white" onClick={drawerOnClick}>
                                            <UnorderedListOutlined />
                                        </IconButton>
                                    </Hidden>
                                </div>
                            </Header>
                            <Layout>
                                <Hidden smDown>
                                    <Sidebar />
                                </Hidden>
                                <Hidden smUp>
                                    <AppDrawer />
                                </Hidden>
                                <Layout
                                    style={{
                                        padding: '0 24px 24px',
                                        background: "#f1f4f6",
                                    }}
                                >
                                    <Content
                                        style={{
                                            padding: 24,
                                            margin: 0,
                                            minHeight: "100vh",
                                            background: "#f1f4f6",
                                        }}
                                    >
                                        <AppRouter/>
                                    </Content>
                                </Layout>
                            </Layout>
                        </Layout>
                    </>
                ) : (
                    <>
                        <Content
                            style={{
                                minHeight: "100vh",
                                background: "#f1f4f6",
                            }}
                        >
                            <AppRouter/>
                        </Content>
                    </>
                )}

            </AppLoading>
        </>
    );
}

export default AppLayout;