import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notes: [],
    userNotes: [],
}

export const noteSlice = createSlice({
    name:"note",
    initialState,
    reducers: {

        setNotes: (state, action) => {
            state.notes = action.payload;
        },

        appendNotes: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.notes,
                ];
            else
                users = [
                    action.payload,
                    ...state.notes,
                ];
            state.notes = users;
        },

        updateNotesById: (state, action) => {
            let users = state.notes?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.notes = users;
        },

        deleteNotesById: (state, action) => {
            state.notes = state.notes?.filter(item => item.id !== action.payload);
        },

        resetNotes: (state) => {
            state.notes = [];
        },

        setUserNotes: (state, action) => {
            state.userNotes = action.payload;
        },

        appendUserNotes: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.userNotes,
                ];
            else
                users = [
                    action.payload,
                    ...state.userNotes,
                ];
            state.userNotes = users;
        },

        updateUserNotesById: (state, action) => {
            let users = state.userNotes?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.userNotes = users;
        },

        deleteUserNotesById: (state, action) => {
            state.userNotes = state.userNotes?.filter(item => item.id !== action.payload);
        },

        resetUserNotes: (state) => {
            state.userNotes = [];
        },
    }
});

export const  {
    setNotes,
    setUserNotes,
    appendNotes,
    appendUserNotes,
    deleteNotesById,
    deleteUserNotesById,
    resetNotes,
    resetUserNotes,
    updateNotesById,
    updateUserNotesById
} = noteSlice.actions;

export default noteSlice.reducer;