import React from 'react';
import {useSelector} from "react-redux";

const IsSuperAdmin = ({
    children
}) => {

    const user = useSelector(state => state.auth.user);

    return (
        <>
            {user.user_type_id == 5 && (
                <>
                    {children}
                </>
            )}
        </>
    );
};

export default IsSuperAdmin;