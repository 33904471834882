import React, {useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import {
    BarChartOutlined,
    BranchesOutlined,
    CalendarOutlined,
    CopyOutlined,
    CreditCardOutlined,
    HomeOutlined,
    UserOutlined,
    LogoutOutlined,
    SettingOutlined
} from "@ant-design/icons";
import {Menu} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../redux/Slicers/authSlice";
import {setDrawer} from "../../../redux/Slicers/appSlice";

const SidebarMenu = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const {drawerOpened} = useSelector(state => state.app);

    const menuItems = useMemo(() => {
        let result = [
            {
                key: `home`,
                icon: React.createElement(HomeOutlined),
                label: `Anasayfa`,
                onClick: () => {
                    if (drawerOpened) {
                        dispatch(setDrawer(false));
                    }
                    if (drawerOpened) {
                        dispatch(setDrawer(false));
                    }
                    navigate("/");
                }
            },
            {
                key: `users`,
                icon: React.createElement(UserOutlined),
                label: `Kullanıcılar`,
                children: [
                    {
                        key: "user-1",
                        label: `Tüm Kullanıcılar`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users");
                        }
                    },
                    {
                        key: "user-2",
                        label: `Adaylar`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users?user_type_id=1");
                        }
                    },
                    {
                        key: "user-3",
                        label: `Üyeler`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users?user_type_id=2");
                        }
                    },
                    {
                        key: "user-4",
                        label: `Antrenörler`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users?user_type_id=3");
                        }
                    },
                    ((user.user_type_id >= 4) && {
                        ...{
                            key: "user-5",
                            label: `Yöneticiler`,
                            onClick: () => {
                                if (drawerOpened) {
                                    dispatch(setDrawer(false));
                                }
                                navigate("/users?user_type_id=4");
                            }
                        },
                    }),
                    {
                        key: "user-6",
                        label: `Pasif Üyeler`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users?status=0");
                        }
                    },
                    {
                        key: "user-7",
                        label: `Devamsız Üyeler`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users/discontinuous");
                        }
                    },
                    {
                        key: "user-8",
                        label: `Devamlı Üyeler`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users/continuousUser");
                        }
                    },
                    {
                        key: "user-9",
                        label: `Paket Yenilemeyen Üyeler`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/users/packageIndestructible");
                        }
                    },
                ],
            },
            {
                key: `meetings`,
                icon: React.createElement(CalendarOutlined),
                label: `Randevular`,
                children: [
                    {
                        key: "meetings-1",
                        label: `Randevular`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/meetings");
                        }
                    },
                    {
                        key: "meetings-2",
                        label: `Hat Kapatma`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/line-close");
                        }
                    },
                    {
                        key: "meetings-3",
                        label: `Çalışma Saatleri`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/user-times");
                        }
                    },
                    {
                        key: "meetings-4",
                        label: `İptal Olan Randevular`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/canceled-meetings")
                        }
                    },
                ]
            },
            ((user.user_type_id >= 3) && {
                key: `orders222`,
                icon: React.createElement(CreditCardOutlined),
                label: `Paketler`,
                onClick: () => {
                    if (drawerOpened) {
                        dispatch(setDrawer(false));
                    }
                    navigate("/orders");
                }
            }),
            ((user.user_type_id === 5) && {
                key: `branches`,
                icon: React.createElement(BranchesOutlined),
                label: `Şubeler`,
                onClick: () => {
                    if (drawerOpened) {
                        dispatch(setDrawer(false));
                    }
                    navigate("/branches");
                }
            }),
            ((user.user_type_id >= 3) && {
                key: `reports`,
                icon: React.createElement(BarChartOutlined),
                label: `Raporlar`,
                children: [
                    {
                        key: "reports-1",
                        label: `Satış Raporları`,
                        children: [
                            {
                                key: `orders-1-1"`,
                                label: `Satış Türü`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/selling/order-type");
                                }
                            },
                            {
                                key: `orders-1-2"`,
                                label: `Ödeme Türü`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/selling/payment-type");
                                }
                            },
                            {
                                key: `orders-1-3"`,
                                label: `KDV Hariç`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/selling/without-kdv");
                                }
                            },
                            {
                                key: `orders-1-4"`,
                                label: `Komisyon Hariç`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/selling/without-commission");
                                }
                            },
                            {
                                key: `orders-1-5"`,
                                label: `Net Tutar`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/selling/net-fee");
                                }
                            },
                            {
                                key: `orders-1-6"`,
                                label: `Genel Rapor`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/selling/general-reports");
                                }
                            },
                        ]
                    },
                    ((user.user_type_id === 5) && {
                        key: "reports-2",
                        label: `Performans Raporları`,
                        children: [
                            {
                                key: `orders-2-1"`,
                                label: `Seans Raporları`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/performance/seans");
                                }
                            },
                            {
                                key: `orders-2-2"`,
                                label: `Müşteri Dönüşümü`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/performance/customer-transformation");
                                }
                            },
                            {
                                key: `orders-2-3"`,
                                label: `Nereden Duydular`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/performance/where-did-they-hear-it");
                                }
                            },
                            {
                                key: `orders-2-4"`,
                                label: `Deneme Sonuçları`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/performance/trial-results");
                                }
                            },
                            {
                                key: `orders-2-5"`,
                                label: `Deneme Başarı Oranları`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/performance/trial-success-results");
                                }
                            },
                            {
                                key: `orders-2-6"`,
                                label: `Paket Yenilemeyenler`,
                                onClick: () => {
                                    if (drawerOpened) {
                                        dispatch(setDrawer(false));
                                    }
                                    navigate("/statics/performance/package-renewal");
                                }
                            },
                        ]
                    }),

                ]
            }),
            ((user.user_type_id === 5) && {
                key: `content`,
                icon: React.createElement(CopyOutlined),
                label: `Tanımlamalar`,
                children: [
                    {
                        key: "content-1",
                        label: `Pasif Nedenleri`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/passive-reasons");
                        }
                    },
                    {
                        key: "content-2",
                        label: `Ödeme Türleri`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/payment-types");
                        }
                    },
                    {
                        key: "content-3",
                        label: `Sipariş Türleri`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/order-types");
                        }
                    },
                    {
                        key: "content-4",
                        label: `Kullanıcı Türleri`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/user-types");
                        }
                    },
                    {
                        key: "content-41",
                        label: `Randevu Tipleri`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/meeting-types");
                        }
                    },
                    {
                        key: "content-5",
                        label: `Arama Sonuçları`,
                        onClick: () => {
                            if (drawerOpened) {
                                dispatch(setDrawer(false));
                            }
                            navigate("/call-results");
                        }
                    },
                ]
            }),
            ((user.user_type_id === 5) && {
                key: `app-configs`,
                icon: React.createElement(SettingOutlined),
                label: `Uygulama Ayarları`,
                onClick: () => {
                    if (drawerOpened) {
                        dispatch(setDrawer(false));
                    }
                    navigate("/app-configs");
                }
            }),
            {
                key: `logout`,
                icon: React.createElement(LogoutOutlined),
                label: `Çıkış Yap`,
                onClick: () => {
                    handleLogout();
                }
            },
        ]
        return result;

    }, []);

    const handleLogout = () => {
        dispatch(logout());
        if (drawerOpened) {
            dispatch(setDrawer(false));
        }
        navigate("/login");
    }

    return (
        <>
            <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{
                    height: '100%',
                }}
                theme="light"
                items={menuItems}
            />
        </>
    );
};

export default React.memo(SidebarMenu);