import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    times: [],
    userTimes: [],
}

export const userTimeSlice = createSlice({
    name:"order",
    initialState,
    reducers: {

        setTimes: (state, action) => {
            state.times = action.payload;
        },

        appendTimes: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.times,
                ];
            else
                users = [
                    action.payload,
                    ...state.times,
                ];
            state.times = users;
        },

        updateTimesById: (state, action) => {
            let users = state.times?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.times = users;
        },

        deleteTimesById: (state, action) => {
            state.times = state.times?.filter(item => item.id !== action.payload);
        },

        resetTimes: (state) => {
            state.times = [];
        },

        setUserTimes: (state, action) => {
            state.userTimes = action.payload;
        },

        appendUserTimes: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.userTimes,
                ];
            else
                users = [
                    action.payload,
                    ...state.userTimes,
                ];
            state.userTimes = users;
        },

        updateUserTimesById: (state, action) => {
            let users = state.userTimes?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.userTimes = users;
        },

        deleteUserTimesById: (state, action) => {
            state.userTimes = state.userTimes?.filter(item => item.id !== action.payload);
        },

        resetUserTimes: (state) => {
            state.userTimes = [];
        },
    }
});

export const  {setTimes, appendTimes, deleteTimesById, resetTimes, updateTimesById, setUserTimes, appendUserTimes, updateUserTimesById, deleteUserTimesById, resetUserTimes} = userTimeSlice.actions;

export default userTimeSlice.reducer;