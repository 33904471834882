import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    meetings: [],
    userMeetings: [],
}

export const meetingSlice = createSlice({
    name:"order",
    initialState,
    reducers: {

        setMeetings: (state, action) => {
            state.meetings = action.payload;
        },

        appendMeetings: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.meetings,
                ];
            else
                users = [
                    action.payload,
                    ...state.meetings,
                ];
            state.meetings = users;
        },

        updateMeetingsById: (state, action) => {
            let users = state.meetings?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.meetings = users;
        },

        deleteMeetingsById: (state, action) => {
            state.meetings = state.meetings?.filter(item => item.id !== action.payload);
        },

        resetMeetings: (state) => {
            state.meetings = [];
        },

        setUserMeetings: (state, action) => {
            state.userMeetings = action.payload;
        },

        appendUserMeetings: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.userMeetings,
                ];
            else
                users = [
                    action.payload,
                    ...state.userMeetings,
                ];
            state.userMeetings = users;
        },

        updateUserMeetingsById: (state, action) => {
            let users = state.userMeetings?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.userMeetings = users;
        },

        cancelUserMeetingsById: (state, action) => {
            var meetings =state.userMeetings;
            let index = state?.userMeetings?.findIndex(item => item.id === action.payload);
            meetings[index]["status"] = 0;
            state.userMeetings = meetings;
        },

        deleteUserMeetingsById: (state, action) => {
            state.userMeetings = state.userMeetings?.filter(item => item.id !== action.payload);
        },

        resetUserMeetings: (state) => {
            state.userMeetings = [];
        },
    }
});

export const  {cancelUserMeetingsById, setMeetings, appendMeetings, deleteMeetingsById, resetMeetings, updateMeetingsById, setUserMeetings, appendUserMeetings, updateUserMeetingsById, deleteUserMeetingsById, resetUserMeetings} = meetingSlice.actions;

export default meetingSlice.reducer;