import React from 'react';

const IsAdmin = ({
    children,
    authUser
}) => {
    return (
        <>
            {(authUser?.authority === 2 || authUser?.user_type_id === 4 || authUser?.user_type_id === 5) && (
                <>
                    {children}
                </>
            )}
        </>
    );
};

export default IsAdmin;