import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from "jwt-decode";

const initialState = {
    token: null,
    user: null
}

export const authSlice = createSlice({
    name:"auth",
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUserByToken: (state, action) => {
            state.user = jwt_decode(action.payload);
        },
        logout: (state) => {
            state.token = null;
            state.user = null;
            localStorage.removeItem("token");
            localStorage.removeItem("branch_id");
            localStorage.removeItem("expires_in");
            localStorage.removeItem("token_date");
        }
    }
});

export const  {logout,setToken, setUserByToken} = authSlice.actions;

export default authSlice.reducer;