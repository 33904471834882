import React, {useEffect} from 'react';
import useBranch from "../../core/api/useBranch";
import {Select} from "antd";
import {useSelector} from "react-redux";

const BranchesSelect = () => {

    const {selectBranch, getBranchesIfEmpty} = useBranch()

    const branch = useSelector((state) => state.branch);

    const onChange = (val) => {
        selectBranch(val)
    }

    useEffect(() => {
        getBranchesIfEmpty()
    },[])

    return (
        <>
            <Select
                options={branch?.branches?.map(item => ({
                    value: item?.id,
                    label: item?.name
                }))}
                style={{
                    width: "100%"
                }}
                onChange={onChange}
                value={branch?.selected}
                allowClear
            />
        </>
    );
};

export default BranchesSelect;