import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";

const AuthRoutes = ({
    Component
}) => {

    const token = useSelector((state) => state.auth.token)

    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" replace state={{from: location}}/>;
    }

    return (
        <>
            <Component />
        </>
    )
}
export default AuthRoutes;
