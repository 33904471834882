import React from 'react';
const AllRoutes = ({
    Component
}) => {

    return (
        <>
            <Component />
        </>
    )
}
export default AllRoutes;
