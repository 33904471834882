import React, {useCallback, useMemo} from 'react';
import BranchesSelect from "../../BranchesSelect";
import {Avatar, Dropdown, Menu, Space, theme} from "antd";
import Sider from "antd/es/layout/Sider";
import IsAdmin from "../../IsAdmin";
import SidebarMenu from "./SidebarMenu";
import {useDispatch, useSelector} from "react-redux";
import {toggleSide} from "../../../redux/Slicers/appSlice";
import {DownOutlined, UserOutlined} from "@ant-design/icons";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../redux/Slicers/authSlice";
import IsSuperAdmin from "../../IsSuperAdmin";

const Sidebar = () => {

    const dispatch = useDispatch();

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const {user} = useSelector((state) => state.auth);
    const {sideCollapsed} = useSelector((state) => state.app);

    const navigate = useNavigate();

    const onCollapse = useCallback(() => {
        dispatch(toggleSide());
    }, []);

    const items = useMemo(() => [
        {
            key: '1',
            label: "Profilim",
            onClick: () => {
                navigate("/update-my-account");
            }
        },
        {
            key: '2',
            label: "Çıkış Yap",
            onClick: () => {
                dispatch(logout());
                navigate("/login");
            }
        },
    ], []);

    return (
        <>
            <Sider
                collapsible
                collapsed={sideCollapsed}
                onCollapse={onCollapse}
                width={220}
                style={{
                    background: colorBgContainer,
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)"
                }}
            >
                {!sideCollapsed && (
                    <Box pl={3}>
                        <Space direction="vertical" size={16} className="mt-3 mb-3">
                            <Space wrap size={16}>
                                <Avatar size={46} icon={<UserOutlined/>}/>
                                <Dropdown menu={{items}}>
                                <span onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {user?.name}
                                        <DownOutlined/>
                                    </Space>
                                </span>
                                </Dropdown>
                            </Space>
                        </Space>
                        <IsSuperAdmin>
                            <Box pr={3}>
                                <div className="p-2">
                                    <BranchesSelect/>
                                </div>
                            </Box>
                        </IsSuperAdmin>
                    </Box>
                )}
                <SidebarMenu/>
            </Sider>
        </>
    );
};

export default Sidebar;