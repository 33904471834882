import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    meetingTypes: [],
}

export const meetingTypeSlice = createSlice({
    name:"meetingType",
    initialState,
    reducers: {

        setMeetingTypes: (state, action) => {
            state.meetingTypes = action.payload;
        },

        appendMeetingTypes: (state, action) => {
            let meetingTypes = [];
            if(Array.isArray(action.payload))
                meetingTypes = [
                    ...action.payload,
                    ...state.meetingTypes,
                ];
            else
                meetingTypes = [
                    action.payload,
                    ...state.meetingTypes,
                ];
            state.meetingTypes = meetingTypes;
        },

        updateMeetingTypesById: (state, action) => {
            let meetingTypes = state.meetingTypes?.filter(item => item.id !== action.payload.id);
            meetingTypes = [
                action.payload.data,
                ...meetingTypes
            ];
            state.meetingTypes = meetingTypes;
        },

        deleteMeetingTypesById: (state, action) => {
            state.meetingTypes = state.meetingTypes?.filter(item => item.id !== action.payload);
        },

        resetMeetingTypes: (state) => {
            state.meetingTypes = [];
        },
    }
});

export const  {setMeetingTypes, appendMeetingTypes, updateMeetingTypesById, resetMeetingTypes, deleteMeetingTypesById, } = meetingTypeSlice.actions;

export default meetingTypeSlice.reducer;