import React from 'react';
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

const GuestRoutes = ({
    Component
}) => {

    const token = useSelector((state) => state.auth.token)

    if (!!token) {
        return <Navigate to="/"/>;
    }

    return (
        <>
            <Component />
        </>
    )
}
export default GuestRoutes;
