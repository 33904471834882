import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    users: [],
}

export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers: {

        setUsers: (state, action) => {
            state.users = action.payload;
        },

        appendUsers: (state, action) => {
            let users = [];
            if(Array.isArray(action.payload))
                users = [
                    ...action.payload,
                    ...state.users,
                ];
            else
                users = [
                    action.payload,
                    ...state.users,
                ];
            state.users = users;
        },

        updateUsersById: (state, action) => {
            let users = state.users?.filter(item => item.id !== action.payload.id);
            users = [
                action.payload.data,
                ...users
            ];
            state.users = users;
        },

        deleteUsersById: (state, action) => {
            state.users = state.users?.filter(item => item.id !== action.payload);
        },

        resetUsers: (state) => {
            state.users = [];
        },
    }
});

export const  {updateUsersById, deleteUsersById, setUsers, appendUsers, resetUsers} = userSlice.actions;

export default userSlice.reducer;