import {useMemo, useState} from "react";
import axios from "axios";
import useAntNoti from "./useAntNoti";
import {API_URL} from "../configs/apiConfig";
import {useDispatch, useSelector} from "react-redux";
import  {logout} from "../../redux/Slicers/authSlice";


export default function useApi(init) {
    // const app = useSelector((state) => state.app);

    const dispatch = useDispatch();

    const CONFIG = useMemo(
        () => ({
            baseURL: API_URL, // YOUR_API_URL HERE
            headers: {
                "Content-Type": "application/json",
                "lang"        : "tr"
            },
            timeout: 999999999999
        }),
        []
    );

    const [loading, setLoading] = useState(false);

    const noti = useAntNoti();

    const apiRequest = () => {
        const client = axios.create(CONFIG);
        client.interceptors.request.use(
            (res) => {
                let token = localStorage.getItem('token');
                if (!!token) {
                    res.headers["Authorization"] = "Bearer " + token;
                }
                return res;
            }
        )
        client.interceptors.response.use(
            (res) => {
                let data = res.data;
                if(!data?.success && !data?.isValidationError) {
                    noti.error(data?.message);
                }
                //loginsiz
                if(data?.isAuthError) {
                    dispatch(logout());
                }
                return data;
            },
            (err) => {
                console.log(err);
                if(!!err.response?.data && !err?.response?.data?.success && !err?.response?.data?.isValidationError) {
                    noti.error(err?.response?.data?.message);
                }
                //yetki hatası
                if(!!err.response?.data && !err?.response?.data?.permission) {
                    noti.error(err?.response?.data?.message);
                }
                //loginsiz
                if(!!err.response?.data && err?.response?.data?.isAuthError) {
                    dispatch(logout());
                }
                return err.response?.data;
            }
        );
        return client;
    };

    const _checkResponse = (res) => {
        let result = res?.data;

        return res;
    };

    const startRequest = () => {

    };

    const endRequest = () => {

    };

    function search(
        url,
        params = null,
        callback,
        finnaly = () => null,
        error = () => null
    ) {
        apiRequest()
            .post(url, params)
            .then((response) => {
                return callback(response);
            })
            .catch((e) => {
                error(e);
            })
            .finally(() => {
                finnaly();
            });
    }

    function get(
        url,
        params = null,
        callback,
        finnaly = () => null,
        error = () => null
    ) {
        startRequest();
        apiRequest()
            .get(url, {
                params: params,
            })
            .then((response) => {
                return callback(response);
            })
            .catch((e) => {
                console.log(e)
                error(e);
            })
            .finally(() => {
                endRequest();
                finnaly();
            });
    }

    const post = async (
        url,
        data,
        callback,
        error = () => null,
        finnaly = () => null
    ) => {
        startRequest();
        await apiRequest()
            .post(url, data)
            .then((response) => {
                return callback(response);
            })
            .catch((e) => {
                error(e);
            })
            .finally(() => {
                endRequest();
                finnaly();
            });
    };

    function put(
        url,
        data,
        callback,
        finnaly = () => null,
        error = () => null
    ) {
        startRequest();
        apiRequest()
            .put(url, data)
            .then((response) => {
                return callback(response);
            })
            .catch((e) => error(e))
            .finally(() => {
                endRequest();
                finnaly();
            });
    }

    function deleteReq(
        url,
        params = null,
        callback,
        finnaly = () => null,
        error = () => null
    ) {
        startRequest();
        apiRequest()
            .delete(url, {
                params: params,
            })
            .then((response) => {
                _checkResponse(response);
                return callback(response);
            })
            .catch((e) => {
                error(e);
            })
            .finally(() => {
                endRequest();
                finnaly();
            });
    }

    const postForm = async (
        url,
        data,
        callback,
        error = () => null,
        finnaly= () => null,
    ) => {
        startRequest();
        await apiRequest()
            .post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => {
                if (!!callback && typeof callback === "function")
                    return callback(response);
            }).catch(e => {
                if (!!error && typeof error === "function")
                    error(e);
            })
            .finally(() => {
                endRequest();
                if (!!finnaly && typeof finnaly === "function")
                    finnaly();
            });
    }



    return {
        loading,
        get,
        post,
        put,
        deleteReq,
        search,
        postForm
    };
}
