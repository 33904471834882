import {useDispatch, useSelector} from "react-redux";
import {setBranches, setSelected} from "../../redux/Slicers/branchSlice";
import {toggleLoading} from "../../redux/Slicers/appSlice";
import useApi from "../hooks/useApi";

export default function useBranch() {

    const {branches, selected} = useSelector((state) => state.branch);
    const {token} = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const api = useApi();



    const getBranchesIfEmpty = () => {
        if(branches?.length < 1 && !!token) {
            api.get("branches/indexAll", null, (res) => {
                if(res?.success && res?.data?.length) {
                    dispatch(setBranches(res?.data));
                }
            })
        }
    }

    const getBranches = () => {
        dispatch(toggleLoading());
        api.get("branches/indexAll", null, (res) => {
            if(res?.success && res?.data?.length) {
                dispatch(setBranches(res?.data));
            }
        }, () => {
            dispatch(toggleLoading());
        });
    }

    const selectBranch = (val) => {
        localStorage.setItem("branch_id", val);
        dispatch(setSelected(val));
    }

    return {
        branches,
        getBranchesIfEmpty,
        selectBranch,
        selected,
        getBranches
    }
}