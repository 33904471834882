import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    callResults: [],
}

export const callResultSlice = createSlice({
    name:"callResult",
    initialState,
    reducers: {

        setCallResults: (state, action) => {
            state.callResults = action.payload;
        },

        appendCallResults: (state, action) => {
            let callResults = [];
            if(Array.isArray(action.payload))
                callResults = [
                    ...action.payload,
                    ...state.callResults,
                ];
            else
                callResults = [
                    action.payload,
                    ...state.callResults,
                ];
            state.callResults = callResults;
        },

        updateCallResultsById: (state, action) => {
            let callResults = state.callResults?.filter(item => item.id !== action.payload.id);
            callResults = [
                action.payload.data,
                ...callResults
            ];
            state.callResults = callResults;
        },

        deleteCallResultsById: (state, action) => {
            state.callResults = state.callResults?.filter(item => item.id !== action.payload);
        },

        resetCallResults: (state) => {
            state.callResults = [];
        },
    }
});

export const  {setCallResults, appendCallResults, updateCallResultsById, resetCallResults, deleteCallResultsById, } = callResultSlice.actions;

export default callResultSlice.reducer;