import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userTypes: [],
}

export const userTypeSlice = createSlice({
    name:"userType",
    initialState,
    reducers: {

        setUserTypes: (state, action) => {
            state.userTypes = action.payload;
        },

        appendUserTypes: (state, action) => {
            let userTypes = [];
            if(Array.isArray(action.payload))
                userTypes = [
                    ...action.payload,
                    ...state.userTypes,
                ];
            else
                userTypes = [
                    action.payload,
                    ...state.userTypes,
                ];
            state.userTypes = userTypes;
        },

        updateUserTypesById: (state, action) => {
            let userTypes = state.userTypes?.filter(item => item.id !== action.payload.id);
            userTypes = [
                action.payload.data,
                ...userTypes
            ];
            state.userTypes = userTypes;
        },

        deleteUserTypesById: (state, action) => {
            state.userTypes = state.userTypes?.filter(item => item.id !== action.payload);
        },

        resetUserTypes: (state) => {
            state.userTypes = [];
        },
    }
});

export const  {setUserTypes, appendUserTypes, updateUserTypesById, resetUserTypes, deleteUserTypesById, } = userTypeSlice.actions;

export default userTypeSlice.reducer;