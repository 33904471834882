import React, {useCallback} from 'react';
import {Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {toggleDrawer} from "../../../redux/Slicers/appSlice";
import SidebarMenu from "../Sidebar/SidebarMenu";
import IsSuperAdmin from "../../IsSuperAdmin";
import {Box} from "@mui/material";
import BranchesSelect from "../../BranchesSelect";

const AppDrawer = () => {

    const dispatch = useDispatch();

    const {drawerOpened} = useSelector((state) => state.app);

    const onCollapse = useCallback(() => {
        dispatch(toggleDrawer());
    }, []);


    return (
        <>
            <Drawer
                title="Menü"
                placement="left"
                closable={true}
                onClose={onCollapse}
                open={drawerOpened}
                key="left"
            >
                <IsSuperAdmin>
                    <Box pr={3}>
                        <div className="p-2">
                            <BranchesSelect/>
                        </div>
                    </Box>
                </IsSuperAdmin>
                <SidebarMenu />
            </Drawer>
        </>
    );
};

export default AppDrawer;