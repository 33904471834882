import { notification } from "antd";

export default function useAntNoti(
  { placement = "topRight", duration = 3 } = {
    placement: "topRight",
    duration: 3,
  }
) {
  const success = (msg = "İşlem Başarılı!", _placement = placement) => {
    notification["success"]({
      message: "Başarılı",
      description: msg,
      placement: _placement,
      duration: duration,
    });
  };

  const error = (msg = "İşlem hatalı!", _placement = placement) => {
    notification["error"]({
      message: "Hata",
      description: msg,
      placement: _placement,
      duration: duration,
    });
  };

  const info = (msg, _placement = placement) => {
    notification["info"]({
      message: "Bilgi",
      description: msg,
      placement: _placement,
      duration: duration,
    });
  };
  return {
    success,
    error,
    info,
  };
}
