import React, {Suspense, useCallback} from "react";
import {Route, Routes} from "react-router-dom";
import RouteList from "../../pages/Routes";
import AuthRoutes from "./AuthRoutes";
import GuestRoutes from "./GuestRoutes";
import AllRoutes from "./AllRoutes";

const AppRouter = () => {


    const getComponent = useCallback((
        middlewareName,
        component
    ) => {
        const middlewareObject = {
            "auth": () => (
                <AuthRoutes
                    Component={component}
                />
            ),
            "guest": () => (
                <GuestRoutes
                    Component={component}
                />
            ),
            "all": () => (
                <AllRoutes
                    Component={component}
                />
            ),
        }
        return middlewareObject?.[middlewareName]?.() ?? middlewareObject["all"]();
    }, []);

    const mapRoutes = () => {
        return RouteList.map((item, index) => (
            <Route
                key={`routeList--${index}--${item.path}`}
                path={item.path}
                element={getComponent(item.middleware, item.component)}
            />
        ))
    }

    return(
        <>
            <Suspense fallback={"..."}>
                <Routes>

                    {mapRoutes()}

                    {/*<Route path="*" element={<NoMatch />} />*/}
                </Routes>
            </Suspense>
        </>
    );
}

export default AppRouter;