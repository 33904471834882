import {ConfigProvider, theme} from "antd";
import {Provider} from 'react-redux'
import {store} from "./redux/store";
import AuthRouteMiddleware from "./components/AppRouter/AuthRouteMiddleware";
import AppLayout from "./components/AppLayout";
import AuthVerify from "./components/AuthVerify";
import locale from 'antd/locale/tr_TR';
import dayjs from 'dayjs';

import 'dayjs/locale/tr';

function App() {
    return (
        <>
            <Provider store={store}>
                <ConfigProvider
                    theme={{
                        algorithm: theme.defaultAlgorithm,
                        token: {},
                        components: {

                        },
                    }}
                    locale={locale}
                >
                    <AuthRouteMiddleware>
                        <AuthVerify/>
                        <AppLayout/>
                    </AuthRouteMiddleware>
                </ConfigProvider>
            </Provider>
        </>
    );
}

export default App;
