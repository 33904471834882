import jwt_decode from "jwt-decode";
import {useDispatch} from "react-redux";
import {logout} from "../../redux/Slicers/authSlice";
import {useNavigate, } from "react-router-dom";
import {useLocation} from "react-router";
import {useEffect} from "react";

const AuthVerify = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    let location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            const decodedJwt = jwt_decode(token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                dispatch(logout());
                navigate("/login");
            }
        }
    }, [location]);

    return <></>;
};

export default AuthVerify;